document.addEventListener('turbolinks:load', (event) => {

  document.querySelectorAll('a[rel=external]').forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault()
      window.open(link.getAttribute('href'))
    })
  })

  const play_button = document.querySelector('#release button.play')
  const pause_button = document.querySelector('#release button.pause')
  const player = document.getElementById('release-player')

  if (player) {
    pause_button.style.visibility = 'hidden'

    play_button.addEventListener('click', (event) => {
      event.preventDefault()
      player.classList.replace('d-none', 'fadein')
      player.addEventListener('animationend', () => {
        player.classList.remove('fadein')
      }, { once: true })
      player.play()
      play_button.style.visibility = 'hidden'
      pause_button.style.visibility = 'visible'
    })
    pause_button.addEventListener('click', (event) => {
      event.preventDefault()
      player.classList.add('fadeout')
      player.addEventListener('animationend', () => {
        player.classList.replace('fadeout', 'd-none')
      }, { once: true })
      player.pause()
      pause_button.style.visibility = 'hidden'
      play_button.style.visibility = 'visible'
    })
    player.addEventListener('ended', (event) => {
      player.classList.add('fadeout')
      player.addEventListener('animationend', () => {
        player.classList.replace('fadeout', 'd-none')
      }, { once: true })
      player.pause()
      player.currentTime = 0
      pause_button.style.visibility = 'hidden'
      play_button.style.visibility = 'visible'
    })
  }
})
